import React, { Fragment } from 'react';
import './checklistNew.css';
import { loadQuestinaireTemplates, initChecklist } from '@zert-packages/actions/checklistActions';
import { elementCreatedAndLoaded, API_ROOT_WAR, loadHelp, moveAfterDelivery } from '@zert-packages/actions/api';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DOMPurify from 'dompurify';
import Loader, { LoaderAction } from '@zert-packages/components/shared/Loader/Loader';
import ButtonRM from '@zert-packages/components/shared/Button/index';
import CatalogPlacement from '@zert-packages/components/shared/CatalogPlacement/CatalogPlacement';
import { FormattedMessage } from 'react-intl';
import CancelButton from '@zert-packages/components/shared/CallToActionButton/CancelButton';
import { getOverviewSettings } from '@zert-packages/actions/coreReducers';
import getTranslation from '@zert-packages/utils/getTranslation.old';
import RichTextEditor from '@zert-packages/components/shared/RichTextEdit/RichTextEditor';
import { stateToHTML } from 'draft-js-export-html';
import Scroll from '@zert-packages/components/shared/Scroll';
import getMultipleValue from '@zert-packages/utils/getMultipleValue';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { TextField } from '@mui/material';

class TemplateSelector extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <select
        style={{
          color: '#0E3944',
          fontWeight: 500,
          background: 'none',
          border: 'none',
          width: '100%',
          margin: '6px 0 0 -4px'
        }}
        list_id="5"
        onChange={(e) => {
          this.props.onChange(e);
        }}
        value={this.props.selectedValue}
      >
        <option value={-1} disabled={true}></option>
        {this.props.questionairetemplates.map((template, index) => {
          return (
            <option key={index} value={template.versionId}>
              {template.name}
            </option>
          );
        })}
      </select>
    );
  }
}

class ChecklistNewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idtemplate: -1,
      name: '',
      description: null,
      help: null,
      //  multipleValue: [{value: "test_SUB_SUB"},{value: "test sub system"},{value: "konsult"}],
      multipleValue: [],
      multipleValue2: [],
      defaultDescription: '',
      restricted: false
    };
    this.onClick = this.onClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.selectorChange = this.selectorChange.bind(this);
    this.handleChangeDesc = this.handleChangeDesc.bind(this);
    this.assignChagedValues = this.assignChagedValues.bind(this);
    this.assignChagedValues2 = this.assignChagedValues2.bind(this);
  }

  assignChagedValues(data) {
    this.state.multipleValue = data;
    this.forceUpdate();
  }

  assignChagedValues2(data) {
    this.state.multipleValue2 = data;
    this.forceUpdate();
  }

  componentDidMount() {
    if (!this.props.catalogpermits['zert-checklist']) {
      this.props.dispatch(getOverviewSettings('zert-checklist'));
    }
    const {
      match: { params }
    } = this.props;
    this.props.dispatch(loadQuestinaireTemplates(true, false));
    if (!this.props.helpText.checklist) {
      this.props.dispatch(loadHelp('checklist'));
    } else {
      this.setState({
        help: this.props.helpText.checklist
      });
    }

    if (localStorage.getItem('selectedLabel2') != null) {
      this.setState({
        multipleValue2:
          localStorage.getItem('selectedLabel2') == null ? [] : [{ value: localStorage.getItem('selectedLabel2') }]
      });
    }
    this.setState({
      versionId: params.versionId,
      activityId: params.activityid
    });
    // if (!this.props.catalogPlacement) {
    //  this.props.dispatch(getCatalog());
    // }
    const url = window.location.href;
    const parameters = new URLSearchParams(url.split('?')[1]);
    const templateVersionId = parameters.get('template-version-id');
    if (templateVersionId) {
      this.setState({
        idtemplate: templateVersionId
      })
    }
  }

  onClick(e) {
    if (this.state.name == null || this.state.name.length === 0) {
      return;
    }
    if (this.state.idtemplate === -1) {
      this.setState({
        idtemplate: this.props.questionairetemplates[0].versionId,
        defaultDescription:
          this.props.questionairetemplates[0] && this.props.questionairetemplates[0].description
            ? getTranslation(this.props.questionairetemplates[0].description)
            : '',
        instruction:
          this.props.questionairetemplates[0] && this.props.questionairetemplates[0].instruction
            ? getTranslation(this.props.questionairetemplates[0].instruction)
            : ''
      });
    }
    const checklist = {
      name: this.state.name,
      restricted: this.state.restricted,
      description: this.state.description
    };

    this.props.dispatch(
      initChecklist(
        this.state.idtemplate === -1 ? this.props.questionairetemplates[0].versionId : this.state.idtemplate,
        checklist,
        this.state.multipleValue,
        this.props.storeInExplorer && this.props.selectedTreeNode ? this.props.selectedTreeNode.id : null,
        this.state.versionId,
        this.state.activityId
      )
    );
  }

  selectorChange(e) {
    let today = new Date();
    let dd = today.getDate();

    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    if (dd < 10) {
      dd = `0${dd}`;
    }

    if (mm < 10) {
      mm = `0${mm}`;
    }
    today = `${yyyy}-${mm}-${dd}`;
    const template = this.props.questionairetemplates.find((template) => e.target.value === `${template.versionId}`);
    this.setState({
      idtemplate: e.target.value,
      name: `${template.name} ${today}`,
      defaultDescription: template && template.description ? getTranslation(template.description) : '',
      instruction: template && template.instruction ? getTranslation(template.instruction) : ''
    });
    // initCheckList(id);
  }

  componentDidUpdate(prevProps) {
    const basisinpath = window.location.href.indexOf('basis') >= 0;
    if (this.props.newElementCreated && !basisinpath) {
      this.props.history.push(`${API_ROOT_WAR}/checklist/${this.props.newversionid}/${0}/${0}`);
      this.props.dispatch(elementCreatedAndLoaded());
    } else if (this.props.newElementCreated) {
      moveAfterDelivery(this.state.versionId, this.props.history, this.props.dispatch, this.state.activityId);
    } else if (this.state.help == null && this.props.helpText.checklist != null) {
      this.setState({ help: this.props.helpText.checklist });
    }

    if (
      !(this.props.idtemplate === -1 || this.props.idtemplate == null) &&
      this.props.questionairetemplates != null &&
      prevProps.questionairetemplates == null &&
      this.props.questionairetemplates.length > 0
    ) {
      const template = this.props.questionairetemplates[0];
      let today = new Date();
      let dd = today.getDate();

      let mm = today.getMonth() + 1;
      const yyyy = today.getFullYear();
      if (dd < 10) {
        dd = `0${dd}`;
      }

      if (mm < 10) {
        mm = `0${mm}`;
      }
      today = `${yyyy}-${mm}-${dd}`;
      this.setState({
        name: `${template.name} ${today}`,
        defaultDescription: template && template.description ? getTranslation(template.description) : '',
        instruction: template && template.instruction ? getTranslation(template.instruction) : ''
      });
    }
  }

  handleChange(e) {
    this.setState({ name: e.target.value });
  }

  handleChangeDesc(e) {
    const html = stateToHTML(e.getCurrentContent());

    this.setState({ description: html });
  }

  render() {
    if (this.props.error != null) {
      return (
        <div>
          <FormattedMessage
            id="newpage.checklist.error"
            defaultMessage="Error!, {message}"
            values={{ message: this.props.error.message }}
          />
        </div>
      );
    }

    if (this.props.loadingTemplates || this.props.questionairetemplates == null) {
      return (
        <Loader>
          <FormattedMessage id="newpage.checklist.loadtemplates" defaultMessage="Questionnaire templates" />
        </Loader>
      );
    }

    if (this.props.creatingNewElement) {
      return (
        <Loader loaderAction={LoaderAction.Creating}>
          <FormattedMessage id="newpage.checklist.createchecklist" defaultMessage="New checklist" />
        </Loader>
      );
    }

    let catalogAllowedContinue = true;
    if (this.props.catalogpermits != null && this.props.catalogpermits['zert-checklist']) {
      const object = this.props.catalogpermits['zert-checklist'];
      const setuped1 = !object.catalogElementId1 || (object.catalogElementId1 && this.state.multipleValue.length > 0);
      const setuped2 = !object.catalogElementId2 || (object.catalogElementId2 && this.state.multipleValue2.length > 0);

      if (object.catalogStatus === 'any') {
        if (this.state.multipleValue.length === 0 && this.state.multipleValue2.length === 0) {
          catalogAllowedContinue = false;
        }
      }
      if (object.catalogStatus === 'and') {
        if ((!setuped1 && object.catalogElementId1) || (!setuped2 && object.catalogElementId2)) {
          catalogAllowedContinue = false;
        }
      }
      if (object.catalogStatus === 'or') {
        if (!setuped1 && !setuped2) {
          catalogAllowedContinue = false;
        }
      }
    }

    return (
      <Scroll>
        <div className="workfield" style={{ height: 'unset' }}>
          <div
            className="left_panel"
            style={{
              flex: '1',
              backgroundColor: '#F4F8FA',
              padding: '0 0 0 24px',
              display: 'flex',
              flexDirection: 'column',
              minHeight: 'calc(var(--vh, 1vh) * 100 - 56px)'
            }}
          >
            <div
              className="editMember"
              style={{
                backgroundColor: '#F4F8FA',
                height: 'auto',
                boxShadow: 'none'
              }}
            >
              <div className="captionLabel">
                <FormattedMessage id="newpage.checklist.questionairetemplate" defaultMessage="Questionaire Template"/>
                <span className="required">*</span>
              </div>
              <div className="edit" style={{ background: 'none' }}>
                <TemplateSelector
                  questionairetemplates={this.props.questionairetemplates}
                  onChange={this.selectorChange}
                  selectedValue={this.state.idtemplate}
                />
              </div>
              <div className="captionLabel">
                <FormattedMessage id="newpage.checklist.checklisttitle" defaultMessage="Checklist Title" />
                <span className="required">*</span>
              </div>
              <div className="edit" style={{ background: 'none' }}>
                <TextField
                  variant="standard"
                  autoFocus={!this.state.name}
                  fullWidth
                  value={this.state.name}
                  onChange={this.handleChange}
                />
              </div>

              {this.props.catalog && (
                <>
                  <div className="captionLabel">
                    <FormattedMessage
                      id="newpage.checklist.placementincatalog"
                      defaultMessage="Placement in {catalog}"
                      values={{catalog: this.props.catalog.name}}
                    />
                    <span className="required">*</span>
                  </div>
                  <div className="edit" style={{ background: 'none' }}>
                    <CatalogPlacement
                      assignChagedValues={this.assignChagedValues}
                      multipleValue={this.state.multipleValue}
                      catalog={this.props.catalog}
                      loading={this.props.loadingCatalog}
                    />
                  </div>
                </>
              )}

              <div className="edit" style={{ background: 'none' }}>
                <FormControlLabel
                  control={
                    <Switch
                      value={!this.state.restricted}
                      checked={this.state.restricted}
                      onChange={(event) => {
                        this.setState({ restricted: event.target.value === 'true' });
                      }}
                    />
                  }
                  label={<FormattedMessage id="Checklist.Restricted" defaultMessage="Restricted" />}
                />
              </div>

              <div className="captionLabel">
                <FormattedMessage id="newpage.checklist.additionaldesciption" defaultMessage="Additional Description" />
              </div>
              <div className="edit" style={{ background: 'none' }}>
                <RichTextEditor
                  key={`autogrowInput${this.state.idtemplate}`}
                  text={this.state.defaultDescription}
                  onChange={this.handleChangeDesc}
                  onBlur={this.handleChangeDesc}
                />
              </div>
            </div>

            <div
              style={{
                flex: '1',
                justifyContent: 'flex-end',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <div className="createNewButtons">
                <ButtonRM
                  styleName="primary"
                  whenClick={this.onClick}
                  disabled={this.state.name === null || this.state.name.length === 0 || !catalogAllowedContinue || this.state.idtemplate === -1}
                  text=""
                >
                  <FormattedMessage id="newchecklist.create" defaultMessage="Create" />
                </ButtonRM>
                <CancelButton />
              </div>
            </div>
          </div>
          <div
            id="right_panel"
            style={{
              flex: '1',
              padding: '24px'
            }}
          >
            {this.state.help && (
              <div
                className="checklistDesc"
                dangerouslySetInnerHTML={{ __html: this.state.help ? this.state.help : '' }}
              />
            )}
            {this.state.instruction && (
              <div className="checklistDesc">
                <div className="checklistNewDescHeader">
                  <FormattedMessage id="newpage.checklist.instruction" defaultMessage="Instruction" />
                </div>
                <div
                  className="checklistNewDescSubHeader"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(this.state.instruction)
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </Scroll>
    );
  }
}

const mapStateToProps = (state) => ({
  helpText: state.helpText,
  loadingTemplates: state.loadingTemplates,
  questionairetemplates: state.questionairetemplates,
  creatingNewElement: state.creatingNewElement,
  newElementCreated: state.newElementCreated,
  newversionid: state.newversionid,
  catalog: state.catalog,
  loadingCatalog: Object.values(state.loadingCatalogs)
    .reduce((a, v) => a || v, false),
  selectedTreeNode: state.CORE.selectedNode,
  catalogpermits: state.catalogpermits
});

export default withRouter(connect(mapStateToProps)(ChecklistNewPage));
